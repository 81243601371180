import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Kontakt" />
    <section>
      <h1>Kontakt</h1>
      <p>
        Kontakt os på <a className="alink" href="https://www.facebook.com/BIEsportBrslv/" target="_blank" rel="noopener noreferrer">Facebook</a>, kontakt@bi-esport.dk eller via formularen herunder.
      </p>
    </section>
    <section className="contact">
      <form action="https://formspree.io/xayqnjel" method="POST">
        <h3>Dit fulde navn</h3>
        <input className="name" type="text" name="name" placeholder="Dit fulde navn" />
        <h3>Din email</h3>
        <input className="email" type="email" name="_replyto" placeholder="eksempel@domæne.dk" />
        <h3>Hvad vil du skrive?</h3>
        <textarea className="content" name="content" />
        <input className="submit" type="submit" value="Send" />
        <span>Du vil blive videresendt til en ekstern side, men returnerer hertil efter beskeden er sendt.</span>
      </form>
    </section>
  </Layout>
)

export default ContactPage
